<style>

</style>
<script>
export default {
  name: "BanExpiration",
  props: ['banForm', 'options'],
  methods: {
    async updateBanExpiration() {
      if (!this.banForm.days || this.banForm.days === '' || this.banForm.days < 0) this.banForm.days = 0;
      let days = parseInt(this.banForm.days);
      let date = new Date();
      date.setDate(date.getDate() + days);
      this.banForm.expiration = this.currentDateTime(date);
    },
    currentDateTime(date) {
      if (!date || date === null) date = new Date();
      return `${date.getFullYear()}-${(date.getMonth() + 1).pad()}-${date.getDate().pad()}T${date.getHours().pad()}:${date.getMinutes().pad()}`;
    },
    updateDaysCounter() {
      if (Date.parse(this.banForm.expiration) < new Date()) return;
      let days = (Date.parse(this.banForm.expiration) - new Date()) / 1000 / 60 / 60 / 24;
      days = days.toFixed(0);
      if (days < 0) days = 0;
      this.banForm.days = days;
    }
  },
  created() {
    this.updateDaysCounter();
  }
}
</script>
<template>
  <div class="row">
    <div class="col-auto">
      <div class="justify-content-center text-center">
        <b-form-checkbox
            v-model="banForm.permanent"
            :disabled="this.options.permissions.ban.permanent === false || this.options.permissions.ban.temporary === false"
            class="mt-lg-2"
            switch
        >
          <template v-if="banForm.permanent">
            {{ $t('banmanager.add.details.permanent') }}
          </template>
          <template v-else>
            {{ $t('banmanager.add.details.temporary') }}
          </template>
        </b-form-checkbox>
        <span v-if="this.options.permissions.ban.permanent === false && this.options.permissions.ban.temporary === true"
              class="badge badge-warning text-black m-3">
                  {{ $t('banmanager.add.details.temporary_permissions') }}
        </span>
        <span v-if="this.options.permissions.ban.permanent === true && this.options.permissions.ban.temporary === false"
              class="badge badge-warning text-black">
                  {{ $t('banmanager.add.details.permanent_permissions') }}
        </span>
      </div>
    </div>
    <div class="col-auto">
      <b-form-input v-if="!banForm.permanent"
                    id="date-time"
                    v-model="banForm.expiration"
                    :disabled="banForm.permanent"
                    :min="currentDateTime(new Date())"
                    :placeholder="banForm.expiration"
                    type="datetime-local"
                    v-on:input="updateDaysCounter()"/>
    </div>
    <div class="col-auto" style="margin-top: 7px" v-if="!banForm.permanent" for="days">
      {{ $t('banmanager.add.details.days') }}
    </div>
    <div id="days" v-if="!banForm.permanent" class="col-sm-2">
      <b-form-input
          v-model="banForm.days"
          :disabled="banForm.permanent"
          min="0"
          type="number"
          v-on:input="updateBanExpiration()"
      />
    </div>
  </div>
</template>
